import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermission } from "../../../contexts/PermissionContext";

import './PollHome.css'

function PollHome() {
    const { isPermittedResearcher, isAuthenticated } = usePermission()
    const [authState,] = useState(isAuthenticated);
    const [accessState,] = useState(isPermittedResearcher);
    const navigate = useNavigate();
    const { data } = useLocation().state;

    const handleRedirect = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (authState) {
            if (accessState) {
                navigate(`/pesquisa/pesquisador/${e.target.id}`)
            } else {
                navigate(`/pesquisa/respondente/${e.target.id}`)
            }
        } else {
            alert("É necessario estar logado para responder a pesquisa.")
        }
    }


    return (
        <div className="PollHome">
            <div className="center">
                <div className="poll">
                    <div className="poll-title">
                        <h2>{data.questionaryTitle}</h2>
                    </div>
                    <div className="poll-text">
                        <p>
                            {data.questionaryDescription}
                        </p>
                    </div>
                    <div className="poll-duration">
                        <p className="blue">Tempo de duração: {data.questionaryDuration} minutos</p>
                    </div>

                    <div className="poll-button">
                        <button id={data.questionaryId} onClick={(event) => handleRedirect(event)}>Começar!</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PollHome;