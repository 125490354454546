import React from 'react';

import '../Global.css';

import Navbar from '../components/Navbar/Navbar';
import NewsContent from '../components/News/NewsContent';
import Footer from '../components/Footer/Footer';

function News() {
    return (
        <div className="News">
            <Navbar />
            <NewsContent />
            <Footer />
        </div>
    );
}

export default News;