import React from 'react';

import '../Global.css';

import Navbar from '../components/Navbar/Navbar';
import ProductsContent from '../components/Products/ProductsContent';
import Footer from '../components/Footer/Footer'

function Products() {
    return (
        <div>
            <Navbar />
            <ProductsContent />
            <Footer />
        </div>
    );
}

export default Products;