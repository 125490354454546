import { createContext, useState, useContext } from "react";
import { api } from "../services/Api";
import { useCookies } from "react-cookie";
import { postAnswersResearcher, postAnswersRespondent } from "../services/Api";
import { getQuestionary } from "../services/Api";

export const AnswerContext = createContext({});

export function AnswerProvider(props) {
    const [cookies] = useCookies(["dac-auth-token"]);
    const [checkedState, setCheckedState] = useState([[]]);
    const [dummy, setDummy] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [selected, setSelected] = useState([]);

    async function getQuestions(id) {
        api.defaults.headers['Authorization'] = `Bearer ${cookies["dac-auth-token"]}`;

        return getQuestionary(id).then((result) => {

            let newState = new Array(result.questions.length + 1);

            result.questions.forEach(q => {
                switch (q.metric) {
                    case 1:
                        newState[q.position] = new Array(7).fill(false);
                        break;
                    case 2:
                        let question_option = result.options.filter((o) => o.question === q.id);
                        newState[q.position] = new Array(question_option.length + 1).fill(false);
                        break;
                    default:
                        break;
                }
            })

            return {
                newState,
                questions: result.questions,
                options: result.options,
            }
        });

    }



    function updateCheckedState(state) {
        setCheckedState(state);
    }

    const handleCheckboxInputChange = (event, currentQuestion, currentOptions) => {
        let pos = event.target.id.split("-");
        let count = 0;
        currentOptions.forEach((item) => {
            if (checkedState[pos[0]][item.position]) {
                count++
            }
        });

        if (!checkedState[Number(pos[0])][Number(pos[1])] && count >= currentQuestion.max_answers) {
            event.preventDefault();
            event.stopPropagation();
            event.target.checked = false
            setDummy(!dummy);
        }

        setCheckedState((newState) => {
            if (count < currentQuestion.max_answers || newState[Number(pos[0])][Number(pos[1])]) {
                newState[Number(pos[0])][Number(pos[1])] = !newState[Number(pos[0])][Number(pos[1])];
            }

            return newState
        });

    };

    const handleRadioInputChange = (event, currentOptions) => {
        let pos = event.target.id.split("-");

        setCheckedState((newState) => {
            let state = currentOptions.find((item) => newState[Number(pos[0])][item.position]);
            if (state) {
                newState[pos[0]][state.position] = false;
            }
            newState[Number(pos[0])][Number(pos[1])] = !newState[Number(pos[0])][Number(pos[1])];
            return newState
        });
        setDummy(!dummy);
    };

    const handleTextInputChange = (event) => {
        answers[event.target.id] = event.target.value;
        setAnswers(answers);
        setDummy(!dummy);
    }

    const handleSelectInputChange = (event) => {
        selected[event.target.id] = event.target.value;
        setSelected(selected);
        setDummy(!dummy);
    }

    const handleSubmit = async (e, questions, options, likertOptions, respondentData) => {
        if (respondentData.cpf != null) {

            let answer = `{
                "name": "${respondentData.name}",
                "email": "${respondentData.email}",
                "cpf": "${respondentData.cpf}",
                "phone": "${respondentData.phone}",
                "location": "${respondentData.location}",
                "address": "${respondentData.address}",
                "questionary": ${respondentData.questionary},
                "startTime": "${respondentData.startTime}",
                "answers":[
            `;

            const data = generateAnswerString(questions, options, likertOptions, answer);
            postAnswersResearcher(data).then((response) => {
                if (response.name === 'AxiosError') {
                    if (response.response.data.message === "This questionary was already answered by this cpf" && response.response.data.statusCode === 500) {
                        alert("Só é possível responder este formulário uma única vez por CPF!");
                    } else {
                        alert(`${response.response.data.message}`);
                    }
                } else if (response.statusCode === 201) {
                    alert("Resposta enviada com sucesso!");
                    window.location.replace(process.env.REACT_APP_BASE_URL);
                } else {
                    alert("Ocorreu um erro");
                }
            });
        } else {
            let answer = `{
            "address": "${respondentData.address}",
            "questionary": ${respondentData.questionary},
            "startTime": "${respondentData.startTime}",
            "answers":[
            `

            const data = generateAnswerString(questions, options, likertOptions, answer);

            postAnswersRespondent(data).then((response) => {
                if (response.name === 'AxiosError') {
                    if (response.response.data.message === "User already answered this questionary" && response.response.data.statusCode === 500) {
                        alert("Só é possível responder este formulário uma única vez!");
                    } else {
                        alert(`Erro: ${response.response.data.message}`);
                    }
                } else if (response.statusCode === 201) {
                    alert("Resposta enviada com sucesso!");
                    window.location.replace(process.env.REACT_APP_BASE_URL);
                } else {
                    alert("Ocorreu um erro");
                }
            });
        }
    }

    const generateAnswerString = (questions, options, likertOptions, answer) => {
        questions.forEach(question => {
            if (question.group === '') {
                switch (question.metric) {
                    case 1:
                        for (let i = 1; i < 7; i++) {
                            if (checkedState[question.position][i]) {
                                answer +=
                                    `{
                                        "question_id": ${question.id},
                                        "value": ${i},
                                        "description": "${likertOptions[i - 1].description}",
                                        "metric": ${question.metric}    
                                    },`;
                            }
                        }
                        break;
                    case 2:
                        options.forEach((option) => {
                            if (option.question === question.id) {
                                if (checkedState[question.position][option.position]) {
                                    if (option.action === "OTHER_TEXT") {
                                        answer +=
                                            `{
                                                "question_id": ${question.id},
                                                "value": ${option.value},
                                                "description": "${option.description + ': ' + answers[question.position]}",
                                                "metric": ${question.metric}
                                            },`
                                    } else {
                                        answer +=
                                            `{
                                                "question_id": ${question.id},
                                                "value": ${option.value},
                                                "description": "${option.description}",
                                                "metric": ${question.metric}
                                            },`;
                                    }
                                }
                            };
                        });
                        break;
                    case 3:
                        if (answers[question.id] !== undefined && answers[question.id] !== '')
                            answer +=
                                `{
                                    "question_id": ${question.id},
                                    "value": 0,
                                    "description": "${answers[question.id]}",
                                    "metric": ${question.metric}
                                },`;
                        break;
                    case 4:
                        if (answers[question.id] !== undefined && answers[question.id] !== '')
                            answer +=
                                `{
                                    "question_id": ${question.id},
                                    "value": 0,
                                    "description": "${answers[question.id]}",
                                    "metric": ${question.metric}
                                },`;
                        break;
                    case 5:
                        if (selected[question.id] && selected[question.id] !== '') {
                            let option = options.find((option) => {
                                if (option.question === question.id && option.value === Number(selected[question.id])) {
                                    return true;
                                } return false;
                            })
                            if (option)
                                answer +=
                                    `{
                                        "question_id": ${question.id},
                                        "value": ${option.value},
                                        "description": "${option.description}",
                                        "metric": ${question.metric}
                                    },`;
                        }

                        break;
                    case 6:
                        if (answers[question.id] && answers[question.id] !== '')
                            answer +=
                                `{
                                    "question_id": ${question.id},
                                    "value": 0,
                                    "description": "${answers[question.id]}",
                                    "metric": ${question.metric}
                                },`;
                        break;
                    default:
                        break;
                }
            } else {
                switch (question.metric) {
                    case 3:
                        for (let index = 0; index <= 5; index++) {
                            if (answers[question.group + '' + index + question.position] !== undefined && answers[question.group + '' + index + question.position] !== '') {
                                answer +=
                                    `{
                                        "question_id": ${question.id},
                                        "value": 0,
                                        "description": "${answers[question.group + '' + index + question.position]}",
                                        "metric": ${question.metric}
                                    },`;
                            }
                        }
                        break;
                    case 4:
                        for (let index = 0; index <= 5; index++) {
                            if (answers[question.group + '' + index + question.position] !== undefined && answers[question.group + '' + index + question.position] !== '') {
                                answer +=
                                    `{
                                        "question_id": ${question.id},
                                        "value": 0,
                                        "description": "${answers[question.group + '' + index + question.position]}",
                                        "metric": ${question.metric}
                                    },`;
                            }
                        }
                        break;
                    case 5:
                        for (let index = 0; index <= 5; index++) {
                            if (selected[question.group + '' + index + question.position] && selected[question.group + '' + index + question.position] !== '') {
                                let option = options.find((option) => {
                                    if (option.question === question.id && option.value === Number(selected[question.group + '' + index + question.position])) {
                                        return true;
                                    } return false;
                                })
                                if (option)
                                    answer +=
                                        `{
                                            "question_id": ${question.id},
                                            "value": ${option.value},
                                            "description": "${index}. ${option.description}",
                                            "metric": ${question.metric}
                                        },`;
                            }
                        }
                        break;
                    case 6:
                        for (let index = 0; index <= 5; index++) {
                            if (answers[question.group + '' + index + question.position] !== undefined && answers[question.group + '' + index + question.position] !== '') {
                                answer +=
                                    `{
                                        "question_id": ${question.id},
                                        "value": 0,
                                        "description": "${answers[question.group + '' + index + question.position]}",
                                        "metric": ${question.metric}
                                    },`;
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        let finalAnswer = answer.substring(0, answer.length - 1);
        finalAnswer += `]}`

        return JSON.parse(finalAnswer);
    }

    return (
        <AnswerContext.Provider value={{ getQuestions, updateCheckedState, handleSubmit, handleCheckboxInputChange, handleRadioInputChange, handleTextInputChange, handleSelectInputChange, checkedState, answers, selected }}>
            {props.children}
        </AnswerContext.Provider>
    )
}

export function useAnswer() {
    return useContext(AnswerContext);
}