import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import './DownloadFile.css'
import { DownloadXls } from "../../services/Api";
import { Select, MenuItem } from '@material-ui/core';

function DownloadFile(props) {
  const [searchValue, setSearchValue] = useState(1);


  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClick = async () => {
    try {
      const response = await DownloadXls(searchValue);

      if (response.data != null) {
        downloadExcelFile(response.data);

      }
    } catch (err) {
      console.error(err);
    }
  };

  const downloadExcelFile = (dataxls) => {

    const sheetName = 'Sheet';
    const worksheet = XLSX.utils.json_to_sheet(dataxls);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const fileName = 'myData.xlsx';

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='download-component'>
      <Select className='selectdownload' value={searchValue} onChange={handleChange}>
        <MenuItem value={1} label={'Pesquisa_de_opiniao'}>Pesquisa Embarcada</MenuItem>
        <MenuItem value={2} label={'Pesquisa_Origem-Destino'} >Pesquisa Origem-Destino</MenuItem>
      </Select>


      <button className='download' onClick={handleClick}>Download</button>

    </div>
  );
}

export default DownloadFile;
