import React from 'react';

import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import PollHome from '../components/Polls/PollHome/PollHome';
import Footer from '../components/Footer/Footer'

function PollsHome() {
    return (
        <div className="Polls">
            <Navbar />
            <PollHome />
            <Footer />
        </div>
    );
}

export default PollsHome;