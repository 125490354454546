import React, { useRef, useState } from 'react';
import Spinner from '../components/spinner/Spinner';
import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import PollList from '../components/Polls/PollList/PollList';
import { useEffect } from 'react';
import { getPolls } from '../services/Api';

function Polls() {
    const [result, setResult] = useState({
        statusCode: 0,
        polls: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const req = useRef(false);

    useEffect(() => {
        if (!req.current) {
            req.current = true;
            setIsLoading(true);
            getPolls()
                .then((result) => {
                    setIsLoading(false);
                    setResult(result);
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }, []);

    return (
        <div>
            <Navbar />

            {isLoading ? (
                <div className="Polls">
                    <Spinner customText={"Carregando "} />
                </div>
            ) : (
                <div className="Polls">
                    <PollList polls={result.polls} />
                    <Footer />
                </div>
            )}


        </div>
    )

}

export default Polls;